import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            APP_URL
            title
            description
            author
            ogImage
            ogDescription
            ogTitle
            ogUrl
            fbDomainVerify
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
