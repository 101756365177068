/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import useSiteMetadata from '../hooks/useSiteMetadata';

const SEO = ({ description, lang, meta, title, ...props }) => {
  const siteMetadata = useSiteMetadata();

  const metaTitle = `Quashed | ${title || siteMetadata.title}`;
  const metaDescription = description || siteMetadata.description;
  let ogTitle = props.ogTitle || siteMetadata.ogTitle;
  let ogDescription = props.ogDescription || metaDescription;
  let ogUrl = props.ogUrl
    ? siteMetadata.ogUrl + props.ogUrl
    : siteMetadata.ogUrl;
  let ogImage = props.ogImage || siteMetadata.ogImage;
  const fbDomainVerify = siteMetadata.fbDomainVerify;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate="%s"
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: fbDomainVerify,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  blogMeta: PropTypes.objectOf(PropTypes.string),
};

export default SEO;
