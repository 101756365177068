import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { segmentTrackEvent } from '../common/utils';
import styles from './styles/LinkButton.module.scss';

const LinkButton = ({
  track,
  background,
  href,
  children,
  className,
  target,
  eventName,
}) => {
  const LinkComponent = track ? OutboundLink : Link;
  const componentProps = track ? { href, target } : { to: href };
  const onClick = () => {
    if (eventName) {
      segmentTrackEvent(eventName);
    }
  };

  return (
    <LinkComponent
      style={{ background }}
      {...componentProps}
      onClick={onClick}
      className={classnames('link-button', styles.button, className)}
    >
      {children}
    </LinkComponent>
  );
};

export default LinkButton;
